<template>
  <div class="data-protection-rights">

    <h1>Your Data Protection Rights</h1>

    <p>Your rights under data protection regulations are:</p>
    <ol type="a">
      <li>The right to access</li>
      <li>The right of rectification</li>
      <li>The right to erasure</li>
      <li>The right to restrict processing</li>
      <li>The right to data portability</li>
      <li>The right to object to data processing</li>
      <li>Rights related to automating decision-making and profiling</li>
      <li>Right to withdraw consent</li>
      <li>The right to complain to the Information Commissioner’s Office</li>
    </ol>

    <h3>Your rights explained</h3>

    <h4>Right to Access</h4>
    <p>You have the right to access your personal data and details of the purposes of the processing, the
      categories of personal data concerned and the recipients of the personal data. Providing the rights
      and freedoms of others are not affected, we will supply to you a copy of your personal data. Please
      contact the credit union for more information on how to exercise this right.</p>

    <h4>The right to rectification</h4>
    <p>You have the right to have any inaccurate personal data about you corrected and, taking into account
      the purposes of the processing, to have any incomplete personal data about you completed.</p>

    <h4>The right to erasure</h4>
    <p>In some circumstances you have the right to the erasure of your personal data without undue delay.</p>

    <h4>Those circumstances include:</h4>
    <ul>
      <li>the personal data is no longer needed for the purpose it was originally processed</li>
      <li>you withdraw consent you previously provided to process the information</li>
      <li>you object to the processing under certain rules of data protection law</li>
      <li>the processing is for marketing purposes</li>
      <li>the personal data was unlawfully processed</li>
    </ul>
    <p>However, you may not erase this data where we need it to meet a legal obligation or where it necessary for
      the establishment, exercise or defence of legal claims.</p>

    <h4>The right to restrict processing</h4>
    <p>In some circumstances you have the right to restrict the processing of your personal data. Those circumstances are:</p>
    <ul>
      <li>you contest the accuracy of the personal data;</li>
      <li>processing is unlawful but you oppose erasure;</li>
      <li>we no longer need the personal data for the purposes of our processing, but you require personal data
        for the establishment, exercise or defence of legal claims; and</li>
      <li>you have objected to processing, pending the verification of that objection. Where processing has been
        restricted on this basis, we may continue to store your personal data.</li>
    </ul>
    <p>We will only otherwise process it:</p>
    <ol type="a">
      <li>with your consent;</li>
      <li>for the establishment, exercise or defence of legal claims; or</li>
      <li>for the protection of the rights of another natural or legal person;</li>
    </ol>

    <h4>The right to object to processing</h4>
    <p>You have the right to object to our processing of your personal data on grounds relating to your particular
      situation, but only to the extent that the legal basis for the processing is that the data is necessary for
      the purposes of the legitimate interests pursued by us or by a third party.</p>
    <p>If you make such an objection, we will cease to process the personal information unless we can demonstrate
      compelling legitimate grounds for the processing which override your interests, rights and freedoms, or
      the processing is for the establishment, exercise or defence of legal claims.</p>
    <p>You have the right to object to our processing of your personal data for direct marketing purposes
      (including profiling for direct marketing purposes). If you make such an objection, we will cease
      to process your personal data for this purpose.</p>

    <h4>The right to data portability</h4>
    <p>To the extent that the legal basis for our processing of your personal data is:</p>
    <ol type="a">
      <li>consent; or</li>
      <li>that the processing is necessary for the performance of our contract with you</li>
    </ol>
    <p>You have the right to receive your personal data from us in a commonly used and machine-readable format or
      instruct us to send this data to another organisation. This right does not apply where it would adversely
      affect the rights and freedoms of others.</p>

    <h4>Rights related to automatic processing</h4>
    <p>This credit union may use automated processes for processing members’ loan or membership applications to make
      sure that our decisions are quick, fair, efficient, and correct based on what we know. If the credit union
      uses fully automated decision processes then you have the right to have the decision reviewed by a member of
      staff, express their point of view, and obtain an explanation of the decision and challenge it.</p>

    <h4>Right to withdraw consent</h4>
    <p>To the extent that the legal basis for our processing of your personal information is your consent, you have
      the right to withdraw that consent at any time. Withdrawal will not affect the lawfulness of processing before the withdrawal.</p>

    <h4>The right to complain to the Information Commissioner’s Office</h4>
    <p>If you consider that our processing of your personal information infringes data protection laws, you have a legal
      right to lodge a complaint with the Information Commissioner’s Office which is responsible for data protection in the UK. You can contact them by:</p>
    <ul>
      <li>Going to their website at: <a href="https://ico.org.uk">https://ico.org.uk</a></li>
      <li>Phone on <a href="tel:03031231113">0303 123 1113</a></li>
      <li>Post to Information Commissioner’s Office, Wycliffe House, Water Lane, Wilmslow, SK9 5AF</li>
    </ul>

    <h4>Contact us about your rights</h4>
    <p>For more information about how your rights apply to your membership of the credit union or to make a request
      under your Subject Access Rights you can contact us on <a href="mailto:enquiries@cashbox.org.uk">enquiries@cashbox.org.uk</a> or <a href="tel:01613423266">0161 342 3266</a></p>

  </div>
</template>

<script>
export default {
  name: 'DataProtectionRights',
  metaInfo() {
    return {
      title: 'Your Data Protection Rights | Cash Box Credit Union',
      meta: [
        { name: 'keywords', content: "" },
        { name: 'description', content: "" }
      ],
    };
  }
}
</script>

<style scoped>

</style>
